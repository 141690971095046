import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import rehypeRaw from 'rehype-raw';
import BlueTick from '../assets/icons/blue-tick.svg';
import PinkTick from '../assets/icons/pink-tick.svg';

import { login } from '@src/utils/mmc-api/auth';

const Subscription = ({ data }) => {
    const user = useSelector((state) => state.user.data);
    const isSubscribed = user?.subscribed ?? false;
    const loggedIn = !!user;

  const handleJoinNowClick = () => {
    if (isSubscribed) return alert('You are already subscribed.');
    if (!loggedIn) {
        localStorage.setItem('redirect', '/subscriptions');
        login();
    } else if (data?.stripeLink) {
      window.open(`${data.stripeLink}?prefilled_email=${user.email}`, '_blank');
    }
  };

  if (!data) return (<div></div>);

  const borderColour = data?.borderColour?.hex ?? 'rgba(185,188,193,1)';

  return (
    <div className="px-1.5 mx-1 mb-2 subscription-container" style={{ borderColor: borderColour }}>
        <h2>{data?.time}</h2>
        <h3 className="mb-0">
            £{data?.price}
            <span className="per-month">/month</span>
        </h3>
        <p className="pb-2 mb-0">
            {
                data?.paymentPlan
                ? data.paymentPlan
                : `Billed ${data?.billedEvery}`
            }
        </p>
        <button
            className="button w-button mx-0 mb-2 large-button"
            onClick={handleJoinNowClick}
        >
            Join now!
        </button>
      <ReactMarkdown className="paragraph" rehypePlugins={[rehypeRaw]} children={data.contents} />
      {data.blueTickContent.map(({ content }) => (
        <div class="srow flex-no-wrap">
            <img src={BlueTick} alt="Blue tick" class="subscription-tick narrow mr-0.25" />
            <p>{content}</p>
        </div>
      ))}
      {data.pinkTickContent.map(({ content }) => (
        <div class="srow flex-no-wrap">
            <img src={PinkTick} alt="Pink tick" class="subscription-tick narrow mr-0.25 scolumn" />
            <p class="scolumn">{content}</p>
        </div>
      ))}
      <ReactMarkdown className="paragraph" rehypePlugins={[rehypeRaw]} children={data.termsAndConditions} />
    </div>
  );
};

Subscription.propTypes = {
  data: PropTypes.object,
  loggedIn: PropTypes.bool.isRequired,
};

export { Subscription };
