import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Layout, SEO } from '../components';
import { Subscription } from '../components/subscription';
import VideoPlayer from '../components/VideoPlayer';

const Subscriptions = ({ data }) => {
    const subscriptions = data.subscriptions.edges.map((subscription) => subscription.node);
    const subscriptionPageDescription = data.subscriptionPage.edges[0].node.description || (
        <div></div>
    );

    const { title, shortDescription } = data.subscriptionPage.edges[0].node;

    const subscriptionPageTermsAndConditions = data.subscriptionPage.edges[0].node
        .termsAndConditions || <div></div>;

    const { video, youtubeVideo, videos } = data.subscriptionPage.edges[0].node;

    const allVideos = [
        ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
        ...(youtubeVideo ? youtubeVideo.split(',') : []),
        ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
    ];

    return (
        <Layout>
            <SEO title="Subscriptions" noIndex={true} />
            <div className="container content-section mb-2 pt-0">
                <h2 className="subscription-title mb-2">{title}</h2>
                <ReactMarkdown
                    className="paragraph mt-1 mb-2"
                    rehypePlugins={[rehypeRaw]}
                    children={shortDescription}
                />
                <div
                    className="subscriptions-container"
                    style={{ gridTemplateColumns: `repeat(${subscriptions.length}, 1fr)` }}
                >
                    {subscriptions.map((subscription, i) => (
                        <Subscription key={i} data={subscription} />
                    ))}
                </div>
                <div className="container-flex flex-col" style={{
                    justifyContent: 'center'
                }}>
                    {allVideos.length
                        ? (
                            <div style={allVideos.length > 1 ? {
                                display: 'grid',
                                gridTemplateColumns: `repeat(${allVideos.length}, 1fr)`,
                                gap: '1rem',
                                width: '100%'
                            } : {
                                maxWidth: '700px',
                                width: '100%'
                            }}>
                                {allVideos.map((src) => <VideoPlayer className="pb-2" src={src} />)}
                            </div>
                        )
                        : null}
                </div>
                <ReactMarkdown
                    className="paragraph mt-1"
                    rehypePlugins={[rehypeRaw]}
                    children={subscriptionPageDescription}
                />
                <h2 className="mt-1">Terms And Conditions</h2>
                <ReactMarkdown
                    className="paragraph my-2"
                    rehypePlugins={[rehypeRaw]}
                    children={subscriptionPageTermsAndConditions}
                />
            </div>
        </Layout>
    );
};

export default Subscriptions;

const query = graphql`
  query SubscriptionQuery {
    subscriptions: allDatoCmsSubscription(sort: { fields: price, order: DESC }) {
      edges {
        node {
          id
          price
          stripeLink
          contents
          time
          billedEvery
          termsAndConditions
          borderColour {
            hex
          }
          blueTickContent {
            content
          }
          pinkTickContent {
            content
          }
          paymentPlan
        }
      }
    }
    subscriptionPage: allDatoCmsSubscriptionPage {
      edges {
        node {
          title
          shortDescription
          description
          termsAndConditions
          video {
            video {
              streamingUrl
            }
          }
          videos {
            video {
              streamingUrl
            }
          }
          youtubeVideo
        }
      }
    }
  }
`;

export { query };
